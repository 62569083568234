import { useEffect, useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import XTwitter from '@/public/x-twitter.svg'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'

const footerNavigation = {
  company: [
    { name: 'Features', href: '/features' },
    { name: 'About', href: '/about' },
    {
      name: 'Careers',
      href: 'https://apply.workable.com/pathwaymedical/',
      openInNewTab: true,
    },
    {
      name: 'Continuous Medical Education (CME)',
      href: '/cme',
    },
    { name: 'Support', href: '/contact' },
    { name: 'Reach Clinicians', href: '/reach-clinicians' },
  ],
  newsletters: [
    {
      name: 'TLDR Primary Care',
      href: 'https://newsletter.pathway.md/primary-care/',
      openInNewTab: true,
    },
    {
      name: 'TLDR Heme / Onc',
      href: 'https://newsletter.pathway.md/tldr-hemeonc',
      openInNewTab: true,
    },
    {
      href: '/tldr',
      name: 'Archive',
      openInNewTab: false,
    },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms of Use', href: '/terms-of-use' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/pathwaymedical',
      icon: FaFacebook,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/pathwaymedical',
      icon: FaInstagram,
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/PathwayMedical',
      icon: XTwitter,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/pathwaymedical',
      icon: FaLinkedin,
    },
  ],
}

export const FooterSection = () => {
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    // Update the year when the component mounts
    setYear(new Date().getFullYear())
  }, [])

  return (
    <footer
      className="border-t border-gray-200 bg-white"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 sm:pt-8 lg:px-8 lg:pt-16">
        <div>
          <div className="xs:grid-cols-2 mt-16 grid grid-cols-1 gap-8">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="lg:pl-12">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        target={item.openInNewTab ? '_blank' : '_self'}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0 lg:pl-12">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Newsletters
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.newsletters.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        target={item.openInNewTab ? '_blank' : '_self'}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="grid-rows-1 md:grid md:grid-cols-2 md:gap-8">
              <div className="lg:pl-12">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-10 md:mt-0 lg:pl-12">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Mobile apps
                </h3>
                <div className="mt-6 flex flex-col gap-y-3">
                  <Link
                    href="https://pathwaymd.onelink.me/LROn/439f4b9c"
                    target="_blank"
                  >
                    <Image
                      width={150}
                      height={75}
                      src="/marketing/app-store-icon.svg"
                      alt="App store icon"
                    />
                  </Link>
                  <Link
                    href="https://pathwaymd.onelink.me/LROn/439f4b9c"
                    target="_blank"
                  >
                    <Image
                      width={150}
                      height={75}
                      src="/marketing/google-play-icon.svg"
                      alt="Google Play icon"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
          <div className="flex space-x-6 md:order-2">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-5 w-5" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
            &copy; {year} Pathway Medical, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
