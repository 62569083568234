import Head from 'next/head'

interface MetaHeadInterface {
  canonical?: string
  title: string
  description: string
  image?: string
  type?: 'article' | 'website'
  twitterCard?: string
}

export const HeadMeta = ({
  canonical,
  title,
  description,
  image = '/marketing/metaimage.jpg',
  twitterCard,
  type = 'website',
}: MetaHeadInterface) => {
  return (
    <Head>
      <title>{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}

      <meta property="og:title" content={title} />

      {description && (
        <>
          <meta name="description" content={description} key="desc" />
          <meta property="og:description" content={description} />
        </>
      )}
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />

      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
    </Head>
  )
}
