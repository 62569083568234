import { useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import { useBreakpointValue } from '@chakra-ui/react'

import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import useWindowScroll from '@react-hook/window-scroll'
import clsx from 'clsx'

export const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const scrollY = useWindowScroll(0)
  const isMobileSreen = useBreakpointValue({
    base: true,
    sm: false,
  })
  const navigation = [
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact' },
  ]

  return (
    <>
      <nav
        className={clsx(
          'sticky top-0 z-50 w-full bg-white',
          scrollY > 0 &&
            !isMobileSreen &&
            'sm:border-b sm:border-gray-200 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-saturate-50',
          scrollY > 0 && isMobileSreen && 'border-b border-gray-200',
        )}
        aria-label="Global"
      >
        <div className="mx-auto flex max-w-6xl items-center justify-between p-4 px-6 lg:px-8">
          {/* Logo */}
          <div className="flex lg:flex-1 ">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Pathway</span>
              <Image
                src="/marketing/pathway-logo-refreshed.svg"
                alt="Pathway Logo"
                width={116}
                height={24}
                className="max-h-8"
              />
            </Link>
          </div>
          {/* Mobile hamburger button */}
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {/* Desktop menu */}
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="text-sm leading-6 text-gray-900"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end">
            <Link
              href="/auth/sign-in"
              className="text-sm font-medium leading-6 text-black"
            >
              Sign in
            </Link>
            <Link
              className="hover:opacity-85 ml-4 rounded-md bg-black px-2.5 py-1 text-sm font-semibold text-white"
              href="/home"
            >
              Browse
            </Link>
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-7 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            {/* Fixes logo getting highlighted in Safari */}
            <Link href="/" className="block focus:outline-none sm:hidden">
              <Image
                src="/marketing/pathway-logo-refreshed.svg"
                alt="Pathway Logo"
                width={115}
                height={32}
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  href="/auth/sign-in"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Sign in
                </Link>
                <Link
                  href="/auth/sign-up"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50 dark:text-white"
                >
                  Get Pathway Free
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
